import React, { Fragment, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { vendorAlternativesStyles as styles } from "./vendor-alternatives.styles";
import { IconErrorData, IconVendorAlternateError } from "../../icons";
import { ProductAlternative } from "../../app.types";
import { ProductOverviewContext } from "../../context/product-overview.context";
import * as singleSpa from "single-spa";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ProductLogo, TooltipV2, TypographyComponent } from "gx-npm-ui";

const VendorAlternatives: React.FC = () => {
  const useStyles = makeStyles(() => styles);
  const classes = useStyles();
  const {
    alternativeProducts,
    initId,
    isError,
    isFromProductProfile,
    productName,
    setInitProdId,
    setProductId,
    templateId,
  } = useContext(ProductOverviewContext);
  const { t } = useTranslation();
  /**
   *
   * @param product
   */
  const handleTemplateProductClick = (product: ProductAlternative) => {
    setProductId(product.id);
    singleSpa.navigateToUrl(`/s/market/${templateId}/product/${product.id}`, {
      replace: true,
    });
  };

  /**
   *
   * @param product
   */
  const handleInitiativeProductClick = (product: ProductAlternative) => {
    const url = `/s/evaluation/${initId}/product/${product.initProdId}/profile`;
    setInitProdId(product.initProdId);
    singleSpa.navigateToUrl(url, {
      replace: true,
    });
  };

  const loadAlternativeVendorData = () => {
    return Array.isArray(alternativeProducts) && alternativeProducts.length ? loadData() : loadNoDataContainer();
  };

  const loadData = () => {
    const topVendorAlternatives = alternativeProducts.slice(0, 3);
    return (
      <Fragment>
        <TypographyComponent boldness={"medium"} styling={"p4"} rootClassName="p4">
          {t("Companies who evaluated")} <TypographyComponent boldness={"medium"}>{productName}</TypographyComponent>,{" "}
          {t("also considered these vendors.")}
        </TypographyComponent>
        <div className={classes.alternateVendorsContainer}>
          {topVendorAlternatives.map((product: ProductAlternative, index) => {
            return (
              <TooltipV2
                enterNextDelay={2000}
                key={product.piProductId}
                placement="top-start"
                PopperProps={{ modifiers: { offset: { offset: "-64px, 8px" } } }}
                rootClassName="tooltipArrow"
                title={t("Click to view profile")}
              >
                <button
                  className={classNames(
                    classes.alternativeVendorButton,
                    index === topVendorAlternatives.length - 1 && classes.lastVendor
                  )}
                  key={product.piProductId}
                  onClick={() =>
                    isFromProductProfile ? handleInitiativeProductClick(product) : handleTemplateProductClick(product)
                  }
                  tabIndex={0}
                  aria-label={t("product button")}
                >
                  <div className={classes.logoWrapper}>
                    <ProductLogo
                      imageWidth="32px"
                      imageHeight="32px"
                      logo={product.productLogo}
                      name={product.productName}
                    />
                  </div>
                  <div aria-label={t("Vendor name")}>
                    <TypographyComponent boldness="medium" styling={"p3"}>
                      {product.productName}
                    </TypographyComponent>
                  </div>
                </button>
              </TooltipV2>
            );
          })}
        </div>
      </Fragment>
    );
  };

  const loadNoDataContainer = () => {
    return (
      <div className={classes.noDataContainer}>
        <IconVendorAlternateError />
        <TypographyComponent styling={"p3"} boldness={"regular"} color={"carbon"}>
          {t("This information is not available.")}
        </TypographyComponent>
      </div>
    );
  };

  const loadErrorContainer = () => {
    return (
      <div className={classes.errorState}>
        <IconErrorData />
        <TypographyComponent styling={"p3"} boldness={"regular"} color={"carbon"}>
          {t("Oops... we were unable to load this information.")}
        </TypographyComponent>
      </div>
    );
  };

  return (
    <div className={classNames(classes.root)}>
      <div className={classes.container}>
        <TypographyComponent type="p2" boldness="medium" color="carbon">
          {t("Top alternative vendors")}
        </TypographyComponent>
        {isError ? loadErrorContainer() : loadAlternativeVendorData()}
      </div>
    </div>
  );
};

export { VendorAlternatives };
