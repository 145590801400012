import { colorPalette } from "gx-npm-common-styles";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RichTooltip, TextLink, TypographyComponent, UsernameDisplay } from "gx-npm-ui";
import { InfoLightIcon } from "gx-npm-icons";
import ProposalReviewSVG from "./assets/proposal-review-icon.svg";
import PRDefaultSVG from "./assets/pr-default-bg.svg";
import PRAvailableSVG from "./assets/pr-available.svg";
import styles from "./proposal-review.module.scss";
import { addQueryParams, useUserState } from "gx-npm-lib";
import { ProductOverviewContext } from "../../context/product-overview.context";

const ProposalReview = () => {
  const { t } = useTranslation();
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const tooltipRef = useRef<HTMLButtonElement>(null);
  const { isEntitledPR } = useUserState();
  const { isFromProductProfile, vendorId, productName, teamMembersWithPRAccess } = useContext(ProductOverviewContext);

  const showInsideInitCardWithEntitlement = isEntitledPR && isFromProductProfile;
  const showTeamMembersWithEntitlement = !isEntitledPR && isFromProductProfile;
  const showOutsideInitCardWithEntitlement = isEntitledPR && !isFromProductProfile;
  const showOutsideInitCardWithoutEntitlement = !isEntitledPR && !isFromProductProfile;

  const teamMemberLimit = 5;
  const isTeamLimitExceeded = teamMembersWithPRAccess && teamMembersWithPRAccess.length > teamMemberLimit;
  const additionalMemberCount = teamMembersWithPRAccess && teamMembersWithPRAccess.length - (teamMemberLimit - 1);

  const handleScheduleReviewClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open(
      addQueryParams("https://www.gartner.com/x/proposal-review/submit", {
        vid: vendorId,
        ref: "buysmart",
      }),
      "_blank"
    );
  };

  const handleTrackSubmissionsClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open(`https://www.gartner.com/analyst-inquiry/info`, "_blank");
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img alt="" src={ProposalReviewSVG} />
        <TypographyComponent rootClassName={styles.title} boldness={"medium"} styling={"p2"} color={"gartnerBlue"}>
          {t("Proposal Review")}
        </TypographyComponent>
        <button
          aria-label={t("Gartner proposal review tooltip")}
          className={styles.infoTooltipButton}
          onClick={handleTooltipOpen}
          onKeyDown={handleTooltipOpen}
          ref={tooltipRef}
        >
          <RichTooltip
            anchor={tooltipRef.current}
            content={[
              {
                type: "PARAGRAPH",
                content: t(
                  "A Proposal Review is a 30-minute session between you and a Gartner Expert to review a vendor's proposal prior to signature."
                ),
              },
            ]}
            footerLinkUrl={"https://intercom.help/gartner-buysmart-help-center/en/articles/9971906-proposal-review"}
            footerLinkText={t("Learn more")}
            open={isTooltipOpen}
            onClose={handleTooltipClose}
            rootClassName={styles.richTooltipContainer}
            popperPlacement={"top"}
            theme={"light"}
            title={t("Gartner Proposal Review")}
          >
            <InfoLightIcon fillPath={colorPalette.interactions.defaultCta.hex} />
          </RichTooltip>
        </button>
      </div>
      <div className={styles.cards}>
        {showInsideInitCardWithEntitlement && (
          <>
            <div className={styles.prAvailable}>
              <TypographyComponent rootClassName={styles.info} styling={"p3"}>
                {t("Schedule a 30-minute session with a Gartner expert to review the proposal you've received for")}{" "}
                {productName}.
              </TypographyComponent>
              <TypographyComponent rootClassName={styles.ready} boldness={"medium"} styling={"p3"}>
                {t("Ready to schedule a Proposal Review?")}
              </TypographyComponent>
              <TextLink onClick={handleScheduleReviewClick} text={t("Start submission")} />
              <TypographyComponent rootClassName={styles.complete} boldness={"medium"} styling={"p3"}>
                {t("Already submitted?")}
              </TypographyComponent>
              <TextLink onClick={handleTrackSubmissionsClick} text={t("Track my submissions")} />
            </div>
            <img className={styles.prAvailableSVG} alt="" src={PRAvailableSVG} />
          </>
        )}
        {showTeamMembersWithEntitlement && teamMembersWithPRAccess.length > 0 && (
          <div className={styles.team}>
            <TypographyComponent rootClassName={styles.info} styling={"p3"}>
              {t(
                "Proposal reviews are only available for select Gartner licenses. The following team member(s) can schedule a Proposal Review for"
              )}{" "}
              {productName}.
            </TypographyComponent>
            <div className={styles.teamMembers}>
              {isTeamLimitExceeded && (
                <div>
                  {teamMembersWithPRAccess.slice(0, 4).map((member, index) => (
                    <UsernameDisplay
                      showName={member.hasViewed}
                      showEmail={!member.hasViewed}
                      isUnknown={!member.hasViewed}
                      key={index}
                      name={member.fullName}
                      email={member.email}
                    />
                  ))}
                  <div className={styles.additionalMembers}>
                    <TypographyComponent
                      rootClassName={styles.additionalMembersBadge}
                      styling={"p1"}
                      boldness={"medium"}
                    >
                      {`+${additionalMemberCount}`}
                    </TypographyComponent>
                    <TypographyComponent rootClassName={styles.additionalMembersText} styling={"p3"}>
                      {teamMembersWithPRAccess
                        .slice(4)
                        .map((member) => member.fullName)
                        .join(", ")}
                    </TypographyComponent>
                  </div>
                </div>
              )}
              {!isTeamLimitExceeded && (
                <div>
                  {teamMembersWithPRAccess &&
                    teamMembersWithPRAccess.map((member, index) => (
                      <UsernameDisplay
                        showName={member.hasViewed}
                        showEmail={!member.hasViewed}
                        isUnknown={!member.hasViewed}
                        key={index}
                        name={member.fullName}
                        email={member.email}
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        )}
        {showTeamMembersWithEntitlement && teamMembersWithPRAccess.length === 0 && (
          <>
            <TypographyComponent rootClassName={styles.noTeamMembersFound} styling={"p3"}>
              {t("The current Gartner license held by your team is not eligible for this service.")}
            </TypographyComponent>
            <TypographyComponent styling={"p3"}>
              {t(
                "For more details, team members with a Gartner license can reach out to their account representatives."
              )}
            </TypographyComponent>
          </>
        )}
        {showOutsideInitCardWithoutEntitlement && (
          <>
            <div className={styles.outsideInitIsEntitledPRProduct}>
              <TypographyComponent styling={"p3"}>
                {t(
                  "Proposal Reviews are only available for select Gartner licenses. Please contact your Gartner Account Manager for more information."
                )}
              </TypographyComponent>
              <TextLink
                href={"https://intercom.help/gartner-buysmart-help-center/en/articles/9971906-proposal-review"}
                target={"_blank"}
                text={t("Learn more")}
                variant="secondary"
              />
            </div>
            <img className={styles.defaultSVG} alt="" src={PRDefaultSVG} />
          </>
        )}
        {showOutsideInitCardWithEntitlement && (
          <>
            <div className={styles.outsideInitIsNotEntitledPRProduct}>
              <TypographyComponent styling={"p3"}>
                {t(
                  "This product is eligible for a Gartner Proposal Review. During an active evaluation, schedule a 30-minute session with a Gartner expert to review the proposal you've received from"
                )}{" "}
                {productName}.
              </TypographyComponent>
              <TextLink
                href={"https://intercom.help/gartner-buysmart-help-center/en/articles/9971906-proposal-review"}
                target={"_blank"}
                text={t("Learn more")}
                variant="secondary"
              />
            </div>
            <img className={styles.defaultSVG} alt="" src={PRDefaultSVG} />
          </>
        )}
      </div>
    </div>
  );
};

export default ProposalReview;
