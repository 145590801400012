import React, { Fragment } from "react";
import { TypographyComponent } from "gx-npm-ui";
import NoFeaturesSVG from "../../../assets/images/no-features.svg";
import { useTranslation } from "react-i18next";
import styles from "./no-features.styles.module.scss";

const NoFeatures = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <TypographyComponent rootClassName={styles.title} color={"carbon"} boldness={"medium"} styling={"p2"}>
        {t("Features and functionality")}
      </TypographyComponent>
      <div className={styles.noFeatures}>
        <img src={NoFeaturesSVG} alt={t("No features")} />
        <TypographyComponent boldness={"medium"} styling={"p3"} color={"iron"}>
          {t("No features are available.")}
        </TypographyComponent>
      </div>
    </Fragment>
  );
};

export default NoFeatures;
