import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { colorPalette } from "gx-npm-common-styles";
import { MQIcon, SquareQuadrantIcon } from "gx-npm-icons";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import { MouseEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClientEvent } from "../../app.constants";
import noMagicQuadrant from "../../assets/images/no-magic-quadrant.svg";
import { ProductOverviewContext } from "../../context/product-overview.context";
import { IconErrorData } from "../../icons";
import { magicQuadrantStyles as styles } from "./magic-quadrant.styles";

const addQueryParam = (url: string, queryParam: string) => {
  return url.includes("?") ? `${url}&${queryParam}` : `${url}?${queryParam}`;
};

type QDPositionText = { [key: string]: string };
const useStyles = makeStyles(() => styles);
const MagicQuadrant = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { hasMq, mq, initId, initProdId, isError, isFromProductProfile, productId, productName } =
    useContext(ProductOverviewContext);
  const captureEvents = useCaptureEventsV2();

  const { qdPosition } = mq;
  const qdPositions = ["Challenger", "Leader", "Niche Player", "Visionary"];
  const qdPositionText: QDPositionText = {
    Challenger: t("Challengers"),
    Leader: t("Leaders"),
    "Niche Player": t("Niche players"),
    Visionary: t("Visionaries"),
  };

  const renderDocURL = (docURL: string) => {
    const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      let eventType, metaData;
      if (isFromProductProfile) {
        eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_MAGIC_QUADRANT_LINK_CLICKED;
        metaData = { initiativeId: initId, initProductId: initProdId, productName };
      } else {
        eventType = ClientEvent.PRODUCT_PROFILE_MAGIC_QUADRANT_LINK_CLICKED;
        metaData = { productId: productId.toString(), productName };
      }
      captureEvents([{ eventType, metaData }]);
      window.open(docURL, "_blank", "noopener");
    };

    return (
      docURL !== null && (
        <TextLink
          href={docURL}
          onClick={handleClick}
          target={"_blank"}
          text={t("Learn more about this Magic Quadrant")}
          variant="secondary"
        />
      )
    );
  };

  const renderData = () => {
    if (isError) {
      return (
        <div className={classes.errorState}>
          <IconErrorData />

          <TypographyComponent styling={"p3"}>
            {t("Oops... we were unable to load this information.")}
          </TypographyComponent>
        </div>
      );
    }

    const { qdDesc, docURL } = mq;

    if (!hasMq || qdPosition === "NONE" || !docURL || docURL.slice(-2) === "-1") {
      return (
        <div className={classes.noMq}>
          <img src={noMagicQuadrant} alt={t("no magic quadrant")} />
          <TypographyComponent styling={"p3"}>
            {t("This vendor is not included in a Magic Quadrant.")}
          </TypographyComponent>
        </div>
      );
    }

    return (
      <>
        <div className={classNames(classes.qdInfo)}>
          <div className={classes.qdType}>
            <TypographyComponent styling="p4" rootClassName={classes.qdSubtitle}>
              {t("Position")}
            </TypographyComponent>
            <TypographyComponent styling="h4" boldness={"medium"} rootClassName={classes.qdText}>
              {qdPositionText[qdPosition]}
            </TypographyComponent>
          </div>
          <div className={classNames(classes.qdContainer)}>
            {qdPositions.map((position) =>
              position === qdPosition ? (
                <div className={classes.qdBoxSelected} key={position}>
                  <TypographyComponent rootClassName={classes.positionTextSelected} styling="p5">
                    {qdPositionText[position]}
                  </TypographyComponent>
                </div>
              ) : (
                <SquareQuadrantIcon
                  height={"76"}
                  width={"76"}
                  key={position}
                  letterSpacing="0.35px"
                  textFill={colorPalette.neutrals.coal.hex}
                  text={qdPositionText[position]}
                  fontSize="12px"
                  fontWeight="500"
                />
              )
            )}
          </div>
        </div>
        <TypographyComponent styling="p4" rootClassName={classes.qdDesc}>
          {qdDesc}
        </TypographyComponent>
        {renderDocURL(addQueryParam(docURL, "ref=buysmartresearch"))}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <MQIcon />
        <TypographyComponent rootClassName={classes.title} styling="p2" boldness="medium" color="gartnerBlue">
          {t("Gartner Magic Quadrant")}
        </TypographyComponent>
      </div>
      {renderData()}
    </div>
  );
};
export default MagicQuadrant;
