// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OFU2di68bqcwiWt1DgV6{padding-right:81px}.OFU2di68bqcwiWt1DgV6 .cJdV4ejN6xw9nAiqN5b3{align-items:center;display:flex;gap:15px;padding-bottom:12px}.OFU2di68bqcwiWt1DgV6 .rpWitpkqynPE1fAVgpbI{width:400px}.OFU2di68bqcwiWt1DgV6 .PebCbRmiHmB3KPjd22Y0{padding-left:12px;width:388px}.OFU2di68bqcwiWt1DgV6 .PebCbRmiHmB3KPjd22Y0 .f2CYiJy5R1KJT0OMUSQV{margin-bottom:12px}.OFU2di68bqcwiWt1DgV6 .PebCbRmiHmB3KPjd22Y0 .f2CYiJy5R1KJT0OMUSQV::marker{color:var(--color-white)}.OFU2di68bqcwiWt1DgV6 .Hhq72xAuYLmjrjGgr6Eg{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px;margin-top:32px}.OFU2di68bqcwiWt1DgV6 .AI0qEu5aCTdZfC0fohmK{margin-bottom:16px}.OFU2di68bqcwiWt1DgV6 ._EtqoWm89VmlDuAVKEWJ{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/research-section/research-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,WAAA,CAGF,4CACE,iBAAA,CACA,WAAA,CAEA,kEACE,kBAAA,CAEA,0EACE,wBAAA,CAKN,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CACA,eAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".root {\n  padding-right: 81px;\n\n  & .header {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .description {\n    width: 400px;\n  }\n\n  & .list {\n    padding-left: 12px;\n    width: 388px;\n\n    & .listItem {\n      margin-bottom: 12px;\n\n      &::marker {\n        color: var(--color-white);\n      }\n    }\n  }\n\n  & .divider {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n    margin-top: 32px;\n  }\n\n  & .learnMore {\n    margin-bottom: 16px;\n  }\n\n  & .linkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `OFU2di68bqcwiWt1DgV6`,
	"header": `cJdV4ejN6xw9nAiqN5b3`,
	"description": `rpWitpkqynPE1fAVgpbI`,
	"list": `PebCbRmiHmB3KPjd22Y0`,
	"listItem": `f2CYiJy5R1KJT0OMUSQV`,
	"divider": `Hhq72xAuYLmjrjGgr6Eg`,
	"learnMore": `AI0qEu5aCTdZfC0fohmK`,
	"linkText": `_EtqoWm89VmlDuAVKEWJ`
};
export default ___CSS_LOADER_EXPORT___;
